import React from 'react';

import Fade from './Fade'
import Heading from './Heading';
import Section from './Section';

const SectionPresentation = () => (
  <Section>
    <div className="row justify-content-md-center">
      <div className="col-md-9">
        <Fade delay="0.6s">
          <Heading>Une agence proche de vous</Heading>
          <p>
            Nous comprenons que le Web n’est pas votre activité principale et
            c’est précisément là que nous vous aidons. Nous sommes une petite
            agence de Québec qui entretient une relation étroite et de confiance
            avec chacun de nos clients.
          </p>
          <p>
            Que ce soit pour la création ou la refonte d’un site, le développement
            d’une application Web ou mobile, Hubble réalisera votre projet.{' '}
            <a href="#contact">Contactez-nous !</a>
          </p>
        </Fade>
      </div>
    </div>
  </Section>
);

export default SectionPresentation;
