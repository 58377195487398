import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Fade = styled.div`
  opacity: 0;
  
  animation: ${fade} 0.3s linear ${({ delay }) => delay} forwards;
`;

Fade.defaultProps = {
  delay: '0s'
};

Fade.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Fade;
