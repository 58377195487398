import * as emailjs from 'emailjs-com';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import Button from './Button';
import Heading from './Heading';
import Section from './Section';
import Spinner from './Spinner';
import icoSuccess from '../images/ico-success.svg';
import theme from '../styles/theme';

const SectionSC = styled(Section)`
  h1 {
    padding-top: 0;
  }
`;

const Form = styled.form`
  margin-top: 10px;

  .recaptchaWrapper .errorMessage {
    top: 0;
  }

  button {
    margin-top: 25px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  opacity: 0;
  position: absolute;
`;

const SuccessWrapper = styled.div`
  padding: 30px;
  text-align: center;
`;

const IcoSuccess = styled.img`
  display: block;
  margin: 0 auto 20px auto;
`;

const ErrorText = styled.div`
  position: relative;
  top: -14px;
  color: ${({ theme }) => theme.errorColor};
  font-weight: 600;
`;

const inputStyles = `
  display: block;
  width: 100%;
  margin-bottom: 17px;
  padding: 22px;
  background: #0000;
  color: #1b3815;
  outline: none;
  border: 1px solid #e6e6e6;
  transition: all ease 0.3s;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #bababa;
  }
  :-ms-input-placeholder {
    color: #bababa;
  }
  :focus {
    border: solid 3px ${theme.accentColor};
    padding: 20px;
  }
`;

const InputText = styled.input`
  ${inputStyles}
`;

const Textarea = styled.textarea`
  min-height: 199px;
  ${inputStyles}
`;

const emailStatuses = {
  INITIAL: 'INITIAL',
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
};

const initialState = {
  error: false,
  value: '',
};

const handleInputChange = setValue => ({ target }) => {
  setValue({ error: false, value: target.value });
};

const renderError = error => {
  if (!error) {
    return null;
  }

  return <ErrorText className="errorMessage">Obligatoire</ErrorText>;
};

const renderTextInput = (state, onChange, placeholder, name) => {
  return (
    <InputWrapper>
      <Label htmlFor={name}>{placeholder}</Label>

      <InputText
        id={name}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={state.value}
      />

      {renderError(state.error)}
    </InputWrapper>
  );
};

const SectionContact = () => {
  const [contactInfos, setContactInfos] = useState({ error: false, value: '' });
  const [message, setMessage] = useState({ error: false, value: '' });
  const [name, setName] = useState({ error: false, value: '' });
  const [recaptcha, setRecaptcha] = useState({ error: false, value: false });
  const [emailStatus, setEmailStatus] = useState(false);

  useEffect(() => {
    emailjs.init('user_rLbcwgxi4npS1h3TR4ZJF');
  }, []);

  const handleRecaptchaChange = response => {
    setRecaptcha({ error: false, value: !!response });
  };

  const handleRecaptchaError = () => {
    setRecaptcha({ error: false, value: true });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    let hasError = false;

    if (!contactInfos.value.trim()) {
      setContactInfos({ error: true, value: contactInfos.value });
      hasError = true;
    }

    if (!message.value.trim()) {
      setMessage({ error: true, value: message.value });
      hasError = true;
    }

    if (!name.value.trim()) {
      setName({ error: true, value: name.value });
      hasError = true;
    }

    if (!recaptcha.value) {
      setRecaptcha({ error: true, value: recaptcha.value });
      hasError = true;
    }

    if (!hasError) {
      try {
        setEmailStatus(emailStatuses.SENDING);

        await emailjs.send('hello@teamhubble.com', 'template_XIapyp1X', {
          contactInfos: contactInfos.value.trim(),
          name: name.value.trim(),
          message: message.value.trim(),
        });

        setEmailStatus(emailStatuses.SENT);

        [setContactInfos, setMessage, setName].forEach(setState => {
          setState(initialState);
        });

        setRecaptcha({ error: false, value: false });
      } catch (error) {
        setEmailStatus(emailStatuses.INITIAL);
        alert("Erreur lors de l'envoi. Veuillez réessayer.");
      }
    }
  };

  const renderForm = () => {
    if (emailStatus === emailStatuses.SENDING) {
      return <Spinner />;
    }

    switch (emailStatus) {
      case emailStatuses.SENDING: {
        return <Spinner />;
      }
      case emailStatuses.SENT: {
        return (
          <SuccessWrapper>
            <IcoSuccess src={icoSuccess} />
            <h1>
              Merci ! Votre message est envoyé. <br />
              Nous vous contacterons très bientôt.
            </h1>
          </SuccessWrapper>
        );
      }
      default: {
        return (
          <Form>
            {renderTextInput(
              name,
              handleInputChange(setName),
              'Votre nom',
              'name'
            )}
            {renderTextInput(
              contactInfos,
              handleInputChange(setContactInfos),
              'Votre courriel et/ou téléphone',
              'courriel'
            )}

            <InputWrapper>
              <Label htmlFor="message">Votre message</Label>
              <Textarea
                id="message"
                placeholder="Votre message"
                onChange={handleInputChange(setMessage)}
                value={message.value}
              />

              {renderError(message.error)}
            </InputWrapper>

            <InputWrapper className="recaptchaWrapper">
              <ReCAPTCHA
                onChange={handleRecaptchaChange}
                onErrored={handleRecaptchaError}
                sitekey="6LfVg7UUAAAAAMhM5tslX-cwJH7z7wxHeRp67erX"
              />

              {renderError(recaptcha.error)}
            </InputWrapper>

            <Button as="button" onClick={handleSubmit} type="submit">
              Envoyer
            </Button>
          </Form>
        );
      }
    }
  };

  return (
    <SectionSC id="contact">
      <div className="row justify-content-md-center">
        <div className="col-md-6">
          <Heading align="left">Faisons connaissance !</Heading>
          <p>
            Soyez à l'aise de nous contacter. Nous sommes toujours ouvert à
            discuter de nouveaux projets, de nouvelles idées ou tous autres
            besoins. Il nous fera plaisir de répondre à vos questions concernant
            les prix pour la création ou la refonte d'une application ou d'un
            site.
          </p>
          <p>
            Vous n'aimez pas les formulaires ? Envoyez nous un simple courriel à{' '}
            <a
              href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#116;&#101;&#097;&#109;&#104;&#117;&#098;&#098;&#108;&#101;&#046;&#099;&#111;&#109;"
              className="email"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@teamhubble.com
            </a>{' '}
            ou appelez-nous au{' '}
            <a className="tel" href="tel:+14182614339">
              418 261-4339
            </a>
            .
          </p>
        </div>

        <div className="col-md-6">{renderForm()}</div>
      </div>
    </SectionSC>
  );
};

export default SectionContact;
