import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.h2`
  margin-bottom: 20px;
  color: '#7B7B7B';
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    font-size: 30px;
    line-height: 36px;
  }
`;

const SubHeading = ({ children }) => <Wrapper>{children}</Wrapper>;

SubHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubHeading;
