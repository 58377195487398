import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Button from './Button';
import Fade from './Fade'
import imgLogo from '../images/logo-hubble.svg';
import imgWave from '../images/bg-hero-wave.svg';
import imgLogoOG from '../images/logo-hubble-og.png';

const Wrapper = styled.header`
  position: relative;
  padding-top: 65px;
  padding-bottom: 150px;
  background-image: linear-gradient(
    222deg,
    ${({ theme }) => theme.brandColor} 8%,
    #18c9b6 48%,
    #009ba4 99%
  );
  color: #ffffff;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${imgWave});
    background-position: bottom -1px center;
    background-repeat: no-repeat;
  }
`;

const Logo = styled.img`
  position: relative;
  display: block;
  left: -15px;
  margin: 0 auto;
  width: 160px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    left: 0;
    margin: initial;
    font-size: 48px;
    line-height: 61px;
  }
`;

const Title = styled.h1`
  margin-top: 75px;
  color: #ffffff;
  font-size: 36px;
  line-height: 48px;
  text-align: left;

  @media ${({ theme }) => theme.breakpoint.sm} {
    font-size: 48px;
    line-height: 61px;
  }
`;

const Strong = styled.strong`
  font-size: 1.2em;
`;

const Subtitle = styled.h2`
  margin: 30px 0;
  font-size: 22px;
  font-weight: normal;
`;

const Header = () => (
  <Wrapper>
    <Helmet>
      <title>
        Hubble - Création de sites Web, applications Web et mobiles à Québec
      </title>
      <meta
        name="description"
        content="Hubble est une agence de développement de sites Web, d'applications mobiles natives et d'applications Web pour tout type d'organisation, entreprises, OBNL ou particulier. Nous sommes situés dans la ville de Québec. "
      />
      <meta
        property="og:title"
        content="Hubble - Création de sites Web, applications Web et mobiles à Québec"
      />
      <meta property="og:site_name" content="Hubble" />
      <meta property="og:url" content="https://teamhubble.com" />
      <meta
        property="og:description"
        content="Hubble est une agence de développement de sites Web, d'applications mobiles natives et d'applications Web pour tout type d'organisation, entreprises, OBNL ou particulier. Nous sommes situés dans la ville de Québec. "
      />
      <meta property="og:type" content="business.business" />
      <meta property="og:image" content={`https://teamhubble.com${imgLogoOG}`} />
    </Helmet>

    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-9">
          <Fade delay="0.3s">
            <Logo src={imgLogo} alt="Logo Hubble" />

            <Title>
              Création de sites Web,
              <br /> d'applications Web et mobiles à&nbsp;Québec
            </Title>

            <Subtitle>
              Pour les commerces, les entreprises et pour <Strong>vous</Strong>
            </Subtitle>

            <Button href="#contact">Nous contacter</Button>
          </Fade>
        </div>
      </div>
    </div>
  </Wrapper>
);

export default Header;
