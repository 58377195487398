import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import imgWaveTopSection from '../images/bg-hero-wave-top-section.svg';
import imgWaveBottomSection from '../images/bg-hero-wave-bottom-section.svg';

const Wrapper = styled.section`
  background: ${({ even, theme }) => (even ? theme.bgLightColor : '#FFFFFF')};

  ${({ even }) => {
    return even
      ? `
        padding: 150px 0;
        background-image: url(${imgWaveTopSection}), url(${imgWaveBottomSection});
        background-position: top center, bottom center;
        background-repeat: no-repeat, no-repeat;
      `
      : `
        padding: 50px 0;
      `;
  }}
`;

const Section = ({ children, ...remainingProps }) => (
  <Wrapper {...remainingProps}>
    <div className="container">{children}</div>
  </Wrapper>
);

Section.defaultProps = {
  even: false,
  id: null,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  even: PropTypes.bool,
  id: PropTypes.string,
};

export default Section;
