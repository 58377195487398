import React from 'react';
import styled from 'styled-components';

import Heading from './Heading';
import Section from './Section';
import SubHeading from './SubHeading';
import imgServiceSiteWeb from '../images/services/site-web.svg';
import imgServiceDiagram from '../images/services/diagram.svg';
import imgServiceMobile from '../images/services/mobile.svg';
import imgServicePlus from '../images/services/plus.svg';

const Services = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const Service = styled.div`
  width: 100%;
  margin-bottom: 60px;

  h2 {
    margin-top: 22px;
    text-align: center;
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    width: 44%;

    h2 {
      padding: 0 20px;
      font-size: 26px;
      line-height: 36px;
    }

    p {
      text-align: justify;
    }
  }
`;

const ImgService = styled.img`
  display: block;
  height: 70px;
  margin: 0 auto;
`;

const SectionPresentation = () => (
  <Section>
    <div className="row justify-content-md-center">
      <div className="col-md-9">
        <Heading>Des services variés et complémentaires</Heading>
        <Services>
          <Service>
            <ImgService
              src={imgServiceSiteWeb}
              alt="Services - Site Web et gestionnaire de contenus"
            />
            <SubHeading>Site Web et gestionnaire de&nbsp;contenus</SubHeading>
            <p>
              Nous concevons votre site Web ou votre boutique en ligne de
              A&nbsp;à&nbsp;Z. Récolte des besoins, prototypages, développements
              et tests sont quelques unes des étapes de réalisation. Vous serez
              impliqués et informés tout au long du projet.
            </p>
            <p>
              Que vous soyez une OBNL, une petite, moyenne ou grande entreprise,
              nous trouverons ensemble la meilleure solution adaptée à votre
              réalité et vos besoins.
            </p>
          </Service>
          <Service>
            <ImgService
              src={imgServiceDiagram}
              alt="Services - Application Web et solutions d'affaires"
            />
            <SubHeading>
              Application Web et solutions&nbsp;d'affaires
            </SubHeading>
            <p>
              Vous avez compris qu'il est possible de grandement améliorer la
              productivité de votre entreprise en investissant dans des outils
              pour automatiser, contrôler ou simplifier vos façons de faire.
            </p>
            <p>
              Nous avons réalisé de nombreuses solutions applicatives qui ont
              transformé le quotidien de nos clients en plus de leur faire
              bénéficier d’un retour sur investissement rapidement.
            </p>
          </Service>
          <Service>
            <ImgService
              src={imgServiceMobile}
              alt="Services - Application mobile Android et Apple"
            />
            <SubHeading>
              Application mobile Android&nbsp;et&nbsp;Apple
            </SubHeading>
            <p>
              Vous souhaitez vous doter d’un outil permettant de moderniser
              certains aspects reliés à vos besoins d’affaires, facilitant le
              travail de votre équipe ou simplifiant vos interactions avec vos
              clients&nbsp;?
            </p>
            <p>
              Une application mobile native pourrait être la solution parfaite
              pour supporter vos opérations. Nos technologies innovantes
              permettent de réduire le temps de développement et donc d'offrir
              une application abordable et de qualité supérieure.
            </p>
          </Service>
          <Service>
            <ImgService src={imgServicePlus} alt="Services - Autres" />
            <SubHeading>Et encore plus de&nbsp;services&nbsp;!</SubHeading>
            <p>
              Nous proposons également une panoplie de services complémentaires:
              Hébergement, formation, consultation ainsi que du sauvetage de
              projet.
            </p>
            <p>
              Selon vos besoins, nous entourons notre équipe de plusieurs
              professionnels compétents dans des domaines complémentaires:
              design, infographie, marketing, etc.
            </p>
            <p>
              <a href="#contact">Contactez-nous</a> pour en savoir davantage.
            </p>
          </Service>
        </Services>
      </div>
    </div>
  </Section>
);

export default SectionPresentation;
