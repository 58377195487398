import styled from 'styled-components';

const SliderWrapper = styled.div`
  margin-left: -50px;

  .slick-dots {
    bottom: -40px;
    margin-left: 40px;
    text-align: left;

    li {
      margin: 0 3px;

      &.slick-active button:before {
        color: ${({ theme }) => theme.linkColor};
      }

      button:before {
        font-size: 10px;
      }
    }
  }
`;

export default SliderWrapper;
