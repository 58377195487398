import React, { useLayoutEffect } from 'react';
import Helmet from 'react-helmet';

import Header from '../components/Header';
import Layout from '../components/Layout';
import SectionContact from '../components/SectionContact';
import SectionPortfolio from '../components/SectionPortfolio';
import SectionPresentation from '../components/SectionPresentation';
import SectionServices from '../components/SectionServices';
import SectionTestimonials from '../components/SectionTestimonials';

const IndexPage = () => {
  useLayoutEffect(() => {
    const { hash } = window.location;

    if (hash && typeof window !== 'undefined') {
      document.querySelector(hash).scrollIntoView(true);
    }
  }, []);

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'fr-CA' }}>
        <title>
          Hubble, Création de sites Web, applications Web et mobiles à Québec
        </title>

        <link
          rel="stylesheet"
          href="https://unpkg.com/bootstrap@4.3.1/dist/css/bootstrap-grid.min.css"
        />

        <link
          href="https://fonts.googleapis.com/css?family=Poppins:400,400i,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Header />
      <SectionPresentation />
      <SectionTestimonials />
      <SectionServices />
      <SectionPortfolio />
      <SectionContact />
    </Layout>
  );
};

export default IndexPage;
