import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 16px 37px;
  background: ${({ theme }) => theme.accentColor};
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    background: ${({ theme }) => theme.accentColorHover};
  }
`;

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
