import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import Heading from './Heading';
import Section from './Section';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
  list-style: none;
`;

const Item = styled.li`
  display: flex;
  position: relative;
  flex: 1;
  max-width: 350px;
  min-width: 250px;
  margin: 1rem 1rem 2rem 1rem;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease-in 0s !important;

  a {
    display: block;
    flex: 1;
    text-decoration: none;
  }

  &:after {
    content: ' ';
    position: absolute;
    display: block;
    height: 50%;
    width: 60%;
    left: 15%;
    bottom: 20px;
    margin: 0 auto;
    box-shadow: 0 2px 50px 0 rgb(158, 158, 158);
    z-index: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in 0s !important;
  }

  &:hover {
    transform: scale(1.03) !important;
    &:after {
      transform: translateY(15px);
    }
  }
`;

const ItemContent = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  z-index: 2;
  background: white;
`;

const Desc = styled.div`
  padding: 31px 10px;
  background: white;
  text-align: center;
  line-height: 1.35em;
`;

const CardTitle = styled.h2`
  color: ${({ theme }) => theme.textColor};
  font-size: 1.3rem;
  font-weight: 800;
  margin-bottom: 0.75rem;
  line-height: 1.2em;
`;

const Summary = styled.div`
  color: #1f1f1f;
  font-size: 0.85rem;
  font-weight: 300;
  line-height: 1.35em;
`;

const SectionPortfolio = () => {
  const { allProjectsJson } = useStaticQuery(graphql`
    query QueryAllProjects {
      allProjectsJson(filter: { active: { eq: "true" } }) {
        edges {
          node {
            id
            shortDescription
            slug
            title
            color
            card {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Section even>
      <div className="row justify-content-md-center">
        <div className="col-md-10">
          <Heading id="portfolio">Quelques unes de nos réalisations</Heading>

          <List>
            {allProjectsJson.edges.map(({ node }) => (
              <Item key={node.id}>
                <a href={`/projets/${node.slug}`}>
                  <ItemContent>
                    <Image fluid={node.card.childImageSharp.fluid} />
                    <Desc>
                      <CardTitle>{node.title}</CardTitle>
                      <Summary>{node.shortDescription}</Summary>
                    </Desc>
                  </ItemContent>
                </a>
              </Item>
            ))}
          </List>
        </div>
      </div>
    </Section>
  );
};

export default SectionPortfolio;
