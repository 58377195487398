import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import Slider from 'react-slick';
import styled from 'styled-components';

import Heading from './Heading';
import Section from './Section';
import Slide from './Slide';
import SliderWrapper from './SliderWrapper';

const SectionSC = styled(Section)`
  padding-bottom: 210px;
`;

const Blockquote = styled.blockquote`
  font-style: italic;
`;

const BlockquoteFooter = styled.footer`
  margin-top: 16px;
  font-style: normal;
`;

const slickSettings = {
  arrows: false,
  centerPadding: '0',
  centerMode: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const SectionTestimonials = () => {
  const { allTestimonialsJson } = useStaticQuery(graphql`
    query AllTestimonialsQuery {
      allTestimonialsJson {
        edges {
          node {
            id
            company
            quote
          }
        }
      }
    }
  `);

  return (
    <SectionSC even>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div className="row justify-content-md-center">
        <div className="col-md-9">
          <Heading>Ce qu’on dit de nous </Heading>
          <SliderWrapper>
            <Slider {...slickSettings}>
              {allTestimonialsJson.edges.map(({ node }) => (
                <Slide key={node.id} isQuote>
                  <Blockquote>
                    {node.quote}
                    <BlockquoteFooter>
                      <cite>{node.company}</cite>
                    </BlockquoteFooter>
                  </Blockquote>
                </Slide>
              ))}
            </Slider>
          </SliderWrapper>
        </div>
      </div>
    </SectionSC>
  );
};

export default SectionTestimonials;
